import axios from "axios";
import { put } from "redux-saga/effects";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* interactionSaga(params) {
  try {
      let interactionResponse = yield axios.post(
          `${env.newBaseURLDev}/workspaces/interaction/interact/`,
          {
            "workspace_id": params.params.workspace_id,
            "entity_id": params.params.entity_id,
            "entity_type": params.params.entity_type
            },
          {
              headers: {
                "Content-Type": "application/json", 
                "Authorization": `Bearer ${localStorage.getItem("token")}`
              },
          }
      );
      console.log(interactionResponse);
  } catch (error) {
    console.log(error);
  }   
}

export function* infoBannerSaga(params) {
  try {
      let infoBannerResponse = yield axios.get(
          `${env.newBaseURLDev}/notifications/banner/get_all?workspace_id=${params.params.workspace_id}`,
          {
              headers: {
                "Content-Type": "application/json", 
                "Authorization": `Bearer ${localStorage.getItem("token")}`
              },
          }
      );
      yield put({ type: "SET_INFO_BANNER", payload: infoBannerResponse.data });
  } catch (error) {
    console.log(error);
  }   
}

