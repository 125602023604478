import { SET_WORKSPACE_ID,SET_ORG_ID,SET_BOARD_ID} from "../actions/types";

const initialState = {
    workspaceId: null,
    orgId: null,
    boardId: null,  
    showConfetti: false,
    helperTab: "assistants",
    helperFilters: {
      sort_by: null,
      filter_by: null,
    },
    infoBanner: null,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {

    case SET_WORKSPACE_ID:
      return {
        ...state,
        workspaceId: action.payload,
      };

    case SET_ORG_ID:
      return {
        ...state,
        orgId: action.payload,
      };

    case SET_BOARD_ID:
      return {
        ...state,
        boardId: action.payload,
      };
    case "TOGGLE_CONFETTI":

      return {
        ...state,
        showConfetti: action.payload,
      };

    case "SET_HELPER_TAB":
      return {
        ...state,
        helperTab: action.payload,
      };

    case "SET_HELPER_FILTERS":
      return {
        ...state,
        helperFilters: action.payload,
      };

    case "SET_INFO_BANNER":
      return {
        ...state,
        infoBanner: action.payload,
      };

    default:
      return state;
  }
}
