import {
  HIDE_DELETE_TASK_MODAL,
  HIDE_INVITE_USER_MODAL,
  HIDE_LEAVE_SITE_MODAL,
  HIDE_MAN_HOURS_MODAL,
  HIDE_NEW_BOARD_MODAL,
  HIDE_NEW_DASHBOARD_MODAL,
  HIDE_NEW_ISSUE_MODAL,
  HIDE_NEW_KPI_CARD,
  HIDE_NEW_SITE_MODAL,
  HIDE_NEW_TASK_MODAL,
  HIDE_WORK_LOG_MODAL,
  SHOW_DELETE_TASK_MODAL,
  SHOW_INVITE_USER_MODAL,
  SHOW_LEAVE_SITE_MODAL,
  SHOW_MAN_HOURS_MODAL,
  SHOW_NEW_BOARD_MODAL,
  SHOW_NEW_DASHBOARD_MODAL,
  SHOW_NEW_ISSUE_MODAL,
  SHOW_NEW_KPI_CARD,
  SHOW_NEW_SITE_MODAL,
  SHOW_NEW_TASK_MODAL,
  SHOW_WORK_LOG_MODAL,
} from "../actions/types";

const initialState = {
  showNewSiteModal: false,
  showNewTaskModal: false,
  showNewBoardModal: false,
  showInviteUserModal: false,
  showLeaveSiteModal: false,
  showDeleteTaskModal: false,
  showWorkLogModal: false,
  showNewDashboardModal: false,
  showNewKPICardModal: false,
  showManHoursModal: false,
  showNewIssueModal: false,
  siteToLeave: null,
  option: null,
  showCreateNewWorkspaceModal: false,
  showDeleteWorkspaceModal: false,
  showChangePlanModal: false,
  deleteWorkspace: null,
  showPermissionDeniedModal: false,
  showCreateAssistantModal: false,
  showImproveWritingModal: false,
  improveWritingInput: "",
  improveWritingLoading: false,
  improveWritingOutput: "",
  editAssistantDetail: null,
  editTaskDetail: null,
  showCreateTaskModal: false,
  createTaskFromAssistant: false,
  createdTaskDetailFromAssistant: "",
  createTaskFromAssistantID: null,
};

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NEW_SITE_MODAL:
      return {
        ...state,
        showNewSiteModal: true,
      };

    case HIDE_NEW_SITE_MODAL:
      return {
        ...state,
        showNewSiteModal: false,
      };

    case SHOW_NEW_TASK_MODAL:
      return {
        ...state,
        showNewTaskModal: true,
      };

    case HIDE_NEW_TASK_MODAL:
      return {
        ...state,
        showNewTaskModal: false,
      };

    case SHOW_NEW_BOARD_MODAL:
      return {
        ...state,
        showNewBoardModal: true,
      };

    case HIDE_NEW_BOARD_MODAL:
      return {
        ...state,
        showNewBoardModal: false,
      };

    case SHOW_INVITE_USER_MODAL:
      return {
        ...state,
        showInviteUserModal: true,
      };

    case HIDE_INVITE_USER_MODAL:
      return {
        ...state,
        showInviteUserModal: false,
      };

    case SHOW_LEAVE_SITE_MODAL:
      return {
        ...state,
        showLeaveSiteModal: true,
        siteToLeave: action.params.site,
      };

    case HIDE_LEAVE_SITE_MODAL:
      return {
        ...state,
        showLeaveSiteModal: false,
        siteToLeave: null,
      };

    case SHOW_DELETE_TASK_MODAL:
      return {
        ...state,
        showDeleteTaskModal: true,
      };

    case HIDE_DELETE_TASK_MODAL:
      return {
        ...state,
        showDeleteTaskModal: false,
      };

    case SHOW_WORK_LOG_MODAL:
      return {
        ...state,
        showWorkLogModal: true,
      };

    case HIDE_WORK_LOG_MODAL:
      return {
        ...state,
        showWorkLogModal: false,
      };

    case SHOW_NEW_DASHBOARD_MODAL:
      console.log(action);
      return {
        ...state,
        showNewDashboardModal: true,
        selectedDashboard: action.params.selectedDashboard,
        option: action.params.option,
      };

    case HIDE_NEW_DASHBOARD_MODAL:
      return {
        ...state,
        showNewDashboardModal: false,
        option: null,
      };

    case SHOW_NEW_KPI_CARD:
      return {
        ...state,
        showNewKPICardModal: true,
      };

    case HIDE_NEW_KPI_CARD:
      return {
        ...state,
        showNewKPICardModal: false,
      };

    case SHOW_MAN_HOURS_MODAL:
      return {
        ...state,
        showManHoursModal: true,
      };

    case HIDE_MAN_HOURS_MODAL:
      return {
        ...state,
        showManHoursModal: false,
      };

    case SHOW_NEW_ISSUE_MODAL:
      return {
        ...state,
        showNewIssueModal: true,
      };

    case HIDE_NEW_ISSUE_MODAL:
      return {
        ...state,
        showNewIssueModal: false,
      };
    case "SHOW_CUSTOM_MODAL":
      return {
        ...state,
        showCustomModal: true,
        customModalData: action.params,
      };

    case "HIDE_CUSTOM_MODAL":
      return {
        ...state,
        showCustomModal: false,
        customModalData: null,
      };
    case "TOGGLE_CREATE_NEW_WORKSPACE_MODAL":
      return {
        ...state,
        showCreateNewWorkspaceModal: !state.showCreateNewWorkspaceModal,
      };
    case "TOGGLE_DELETE_WORKSPACE_MODAL":
      return {
        ...state,
        showDeleteWorkspaceModal: !state.showDeleteWorkspaceModal,
      };
    case "TOGGLE_CHANGE_PLAN_MODAL":
      return {
        ...state,
        showChangePlanModal: !state.showChangePlanModal,
      };
    case "TOGGLE_PERMISSION_DENIED_MODAL":
      return {
        ...state,
        showPermissionDeniedModal: !state.showPermissionDeniedModal,
      };
    case "TOGGLE_CREATE_ASSISTANT_MODAL":
      return {
        ...state,
        showCreateAssistantModal: action.params,
      };
    case "SET_DELETE_WORKSPACE":
      return {
        ...state,
        deleteWorkspace: action.params,
      };
    case "TOGGLE_IMPROVE_WRITING_MODAL":
      return {
        ...state,
        showImproveWritingModal: action.params,
      };
      case "SET_IMPROVE_WRITING_INPUT":
        return {
          ...state,
          improveWritingInput: action.payload,
        };
        case "SET_IMPROVE_WRITING_LOADING":
          return {
            ...state,
            improveWritingLoading: action.payload,
          };
        case "SET_IMPROVE_WRITING_OUTPUT":
          return {
            ...state,
            improveWritingOutput: action.payload,
          };
      case "EDIT_ASSISTANT_DETAIL":
        return {
          ...state,
          editAssistantDetail: action.payload,
        };
    case "EDIT_TASK_DETAIL":
      return {
        ...state,
        editTaskDetail: action.payload,
      };
    case "TOGGLE_CREATE_TASK_MODAL":
      return {
        ...state,
        showCreateTaskModal: action.params,
      };
    case "CREATE_TASK_FROM_ASSISTANT":
      return {
        ...state,
        createTaskFromAssistant: action.payload,
      };
    case "CREATE_TASK_FROM_ASSISTANT_ID":
      return {
        ...state,
        createTaskFromAssistantID: action.payload,
      };
    case "CREATED_TASK_DETAIL_FROM_ASSISTANT":
      return {
        ...state,
        createdTaskDetailFromAssistant: action.payload,
      };
    default:
      return state;
  }
}
