import { SET_HOME_FEED, SET_HOME_FEED_LOADING } from "../actions/types";

const initialState = {
  homeFeed: null,
  homeFeedLoading: false,
  homeFeedFilters: [],
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HOME_FEED:
      return {
        ...state,
        homeFeed: action.payload,
      };

    case SET_HOME_FEED_LOADING:
      return {
        ...state,
        homeFeedLoading: action.payload,
      };

    case "SET_HOME_FEED_FILTERS":
      return {
        ...state,
        homeFeedFilters: action.payload,
      };

    default:
      return state;
  }
}
