import { GET_ALL_BOARDS, SET_ALL_BOARDS, SET_ALL_BOARD_ENTITY,SET_BOARD_MEMBERS} from "../actions/types";

const initialState = {
  allBoardsLoading: false,
  allBoards: [],
  boardMembers: [],
  allBoardEntity:{},
  boardMembersRoles: [],
  showArchive: false,
  boardConfig: {
    action_type: null,
    status: null,
    priority: null,
    sort_by: "",
    search: "",
    assignee: null,
    due_date_range : [null,null],
    view: "board",
  }
};

export default function boardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOARDS:
      return {
        ...state,
        allBoardsLoading: true,
      };

    case SET_ALL_BOARDS:
      return {
        ...state,
        allBoardsLoading: false,
        allBoards: action.data,
      };

    case SET_ALL_BOARD_ENTITY:
      return {
        ...state,
        allBoardEntity: action.data,
      };

    case SET_BOARD_MEMBERS:
      return {
        ...state,
        boardMembers: action.data,
      };  

    case "SET_BOARD_MEMBERS_ROLES":
      return {
        ...state,
        boardMembersRoles: action.data,
      };

    case "TOGGLE_ARCHIVE":
      return {
        ...state,
        showArchive: action.payload,
      };

    case "SET_BOARD_CONFIG":
      return {
        ...state,
        boardConfig: action.payload,
      };
    default:
      return state;
  }
}
