import { GET_ALL_BOARDS, REDIRECT_TO_BOARD, GET_ALL_BOARD_ENTITY,GET_BOARD_MEMBERS } from '../actions/types';

export const getAllBoardsAction = data => ({
    type: GET_ALL_BOARDS,
    params: data,
});

export const redirectToBoardAction = data => ({
    type: REDIRECT_TO_BOARD,
    params: data,
})

export const getAllBoardEntityAction = data => ({
    type: GET_ALL_BOARD_ENTITY,
    params: data,
})

export const getBoardMembersAction = data => ({
    type: GET_BOARD_MEMBERS,
    params: data,
})

export const customiseBoardEntityAction = data => ({
    type: "CUSTOMISE_BOARD_ENTITY",
    params: data,
})

export const deleteBoardEntityAction = data => ({
    type: "DELETE_BOARD_ENTITY",
    params: data,
})


export const createNewIssuePriority = (data) => ({
    type: "CREATE_NEW_ISSUE_PRIORITY",
    params: data,
  });
  
  export const createNewIssueStatus = (data) => ({
    type: "CREATE_NEW_ISSUE_STATUS",
    params: data,
  });
  
  export const reorderBoardEntity = (data) => ({
    type: "REORDER_BOARD_ENTITY",
    params: data,
  });

  export const removeBoardMemberAction = (data) => ({
    type: "REMOVE_BOARD_MEMBER",
    params: data,
  });

  export const getBoardMembersRolesAction = (data) => ({
    type: "GET_BOARD_MEMBERS_ROLES",
    params: data,
  });

  export const updateBoardMembersRolesAction = (data) => ({
    type: "UPDATE_BOARD_MEMBERS_ROLES",
    params: data,
  });

  export const toggleArchiveAction = (data) => ({
    type: "TOGGLE_ARCHIVE",
    payload: data,
  });

  export const setBoardConfigAction = (data) => ({
    type: "SET_BOARD_CONFIG",
    payload: data,
  });
