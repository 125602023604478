import {
  SHOW_NEW_SITE_MODAL,
  HIDE_NEW_SITE_MODAL,
  SHOW_NEW_TASK_MODAL,
  HIDE_NEW_TASK_MODAL,
  SHOW_NEW_BOARD_MODAL,
  HIDE_NEW_BOARD_MODAL,
  SHOW_INVITE_USER_MODAL,
  HIDE_INVITE_USER_MODAL,
  SHOW_LEAVE_SITE_MODAL,
  HIDE_LEAVE_SITE_MODAL,
  SHOW_DELETE_TASK_MODAL,
  HIDE_DELETE_TASK_MODAL,
  SHOW_WORK_LOG_MODAL,
  HIDE_WORK_LOG_MODAL,
  SHOW_NEW_DASHBOARD_MODAL,
  HIDE_NEW_DASHBOARD_MODAL,
  SHOW_NEW_KPI_CARD,
  HIDE_NEW_KPI_CARD,
  SHOW_TOAST,
  UPDATE_OWNER_FILTER,
  HIDE_MAN_HOURS_MODAL,
  SHOW_MAN_HOURS_MODAL,
  SHOW_NEW_ISSUE_MODAL,
  HIDE_NEW_ISSUE_MODAL,
} from "./types";

export const showNewSiteModalAction = () => ({
  type: SHOW_NEW_SITE_MODAL,
});

export const hideNewSiteModalAction = () => ({
  type: HIDE_NEW_SITE_MODAL,
});

export const showNewTaskModelAction = () => ({
  type: SHOW_NEW_TASK_MODAL,
});

export const hideNewTaskModelAction = () => ({
  type: HIDE_NEW_TASK_MODAL,
});

export const showNewBoardModalAction = () => ({
  type: SHOW_NEW_BOARD_MODAL,
});

export const hideNewBoardModalAction = () => ({
  type: HIDE_NEW_BOARD_MODAL,
});

export const showInviteUserModalAction = () => ({
  type: SHOW_INVITE_USER_MODAL,
});

export const hideInviteUserModalAction = () => ({
  type: HIDE_INVITE_USER_MODAL,
});

export const showLeaveSiteModalAction = (site) => ({
  type: SHOW_LEAVE_SITE_MODAL,
  params: site,
});

export const hideLeaveSiteModalAction = () => ({
  type: HIDE_LEAVE_SITE_MODAL,
});

export const showDeleteTaskModalAction = () => ({
  type: SHOW_DELETE_TASK_MODAL,
});

export const hideDeleteTaskModalAction = () => ({
  type: HIDE_DELETE_TASK_MODAL,
});

export const showWorkLogModalAction = () => ({
  type: SHOW_WORK_LOG_MODAL,
});

export const hideWorkLogModalAction = () => ({
  type: HIDE_WORK_LOG_MODAL,
});

export const showNewDashboardModalAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const hideNewDashboardModalAction = () => ({
  type: HIDE_NEW_DASHBOARD_MODAL,
});

export const showNewKPICardAction = () => ({
  type: SHOW_NEW_KPI_CARD,
});

export const hideNewKPICardAction = () => ({
  type: HIDE_NEW_KPI_CARD,
});

export const renameDashboardAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const showNewIssueTypeModalAction = () => ({
  type: SHOW_NEW_ISSUE_MODAL,
});

export const hideNewIssueModalAction = () => ({
  type: HIDE_NEW_ISSUE_MODAL,
});



export const duplicateDashboardAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const modifyViewersDashboardAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const shareDashbaordAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const showDuplicateBoardToastAction = () => ({
  type: SHOW_TOAST,
  data: {
    message:
      "This dashboard is a duplicate of another one, cannot be duplicated again.",
    toastType: 0,
  },
});

export const deleteDashboardAction = (data) => ({
  type: SHOW_NEW_DASHBOARD_MODAL,
  params: data,
});

export const updateOwnerFilterAction = (data) => ({
  type: UPDATE_OWNER_FILTER,
  params: data,
});

export const showManHoursModalAction = () => ({
  type: SHOW_MAN_HOURS_MODAL,
});

export const hideManHoursModalAction = () => ({
  type: HIDE_MAN_HOURS_MODAL,
});

export const showCustomModalAction = (data) => ({
  type: "SHOW_CUSTOM_MODAL",
  params: data,
});

export const hideCustomModalAction = () => ({
  type: "HIDE_CUSTOM_MODAL",
});

export const toggleCreateNewWorkspaceModal = () => ({
  type: "TOGGLE_CREATE_NEW_WORKSPACE_MODAL",
});

export const toggleDeleteWorkspaceModal = () => ({
  type: "TOGGLE_DELETE_WORKSPACE_MODAL",
});

export const setDeleteWorkspaceDetails = (data) => ({
  type: "SET_DELETE_WORKSPACE",
  params: data,
});

export const toggleChangePlanModal = () => ({
  type: "TOGGLE_CHANGE_PLAN_MODAL",
}); 

export const togglePermissionDeniedModal = () => ({
  type: "TOGGLE_PERMISSION_DENIED_MODAL",
}); 

export const toggleCreateAssistantModal = (data) => ({
  type: "TOGGLE_CREATE_ASSISTANT_MODAL",
  params: data,
}); 

export const toggleImproveWritingModal = (data) => ({
  type: "TOGGLE_IMPROVE_WRITING_MODAL",
  params: data,
}); 

export const setImproveWritingInput = (data) => ({
  type: "SET_IMPROVE_WRITING_INPUT",
  payload: data,
}); 

export const setImproveWritingLoading = (data) => ({
  type: "SET_IMPROVE_WRITING_LOADING",
  payload: data,
}); 

export const setImproveWritingOutput = (data) => ({
  type: "SET_IMPROVE_WRITING_OUTPUT",
  payload: data,
}); 

export const editAssistantDetailAction = (data) => ({
  type: "EDIT_ASSISTANT_DETAIL",
  payload: data,
}); 

export const toggleCreateTaskModal = (data) => ({
  type: "TOGGLE_CREATE_TASK_MODAL",
  params: data,
}); 

export const editTaskDetailAction = (data) => ({
  type: "EDIT_TASK_DETAIL",
  payload: data,
}); 

export const createTaskfromAssistantAction = (data) => ({
  type: "CREATE_TASK_FROM_ASSISTANT",
  payload: data,
});

export const createTaskfromAssistantIDAction = (data) => ({
  type: "CREATE_TASK_FROM_ASSISTANT_ID",
  payload: data,
});

export const createdTaskDetailfromAssistantAction = (data) => ({
  type: "CREATED_TASK_DETAIL_FROM_ASSISTANT",
  payload: data,
});